import React, { FC } from 'react';

import { ButtonArea, Chip, Text } from 'components';
import { useFormattedContactCode, useLocale, usePhoneNumber } from 'lib';
import { mdiClipboardTextOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import styles from '../style.module.scss';

type CallProps = {
  phoneNumber: string;
  setStep: (step: number) => void;
  contactCode: string;
};

export const Call: FC<CallProps> = ({
  phoneNumber,
  setStep,
  contactCode,
}) => {

  const onClickClipboardButton = () => {

    navigator.clipboard?.writeText(phoneNumber);

  };

  const { t } = useLocale();

  // Get label and uri of phone number with fallback
  const {
    international: telLabel,
    uri: telUri,
  } = usePhoneNumber(phoneNumber) ?? {
    international: phoneNumber,
    uri: `tel:${phoneNumber}`,
  };

  const formattedContactCode = useFormattedContactCode(contactCode);

  return (
    <>
      <div className={styles.phoneCall}>
        <div className={styles.instructions}>
          {t('Alarm_Detail_Contact.Phone_Call.Instructions').split('\n').map((line, idx) => (
            <Text key={idx} variant={'h6'} textAlign={'center'}>
              {line}
            </Text>
          ))}
        </div>
        <div className={styles.telChip} onClick={onClickClipboardButton}>
          <div className={styles.telCopyToClipboard}>
            <Icon path={mdiClipboardTextOutline}/>
          </div>
          <Chip
            label={telLabel}
            variant={'light200'}
            size={'largest'}
            htmlTag={'a'}
            extraProps={{
              href: telUri,
            }}
          />
        </div>
        {formattedContactCode && (
          <Chip
            variant={'dark'}
            size={'medium'}
            label={formattedContactCode}
          />
        )}
      </div>
      <ButtonArea>
        <Chip
          onClick={() => setStep(1)}
          label={t('Alarm_Detail_Contact.Phone_Call.Done')}
          variant={'green400'}
          size={'large'}
        />
      </ButtonArea>
    </>
  );

};
