import {
  AlarmDetailsState,
  AlarmDetailsResult,
  AbortableThunkArg,
  AlarmPreferences, PopulatedAlarm,
} from 'types';
import {
  createSlice,
  asyncPollableDefaults,
  withPollableFulfilled,
  withPollableRejected,
  api,
  getLocal,
  setLocal,
  withPollablePending,
  createThunk,
  withPollableRetrying,
  childController,
} from 'lib';
import { AlarmSeverity } from '@x-guard/xgac-types/xgac';
import { fetchDynamicResponse } from 'features/details/response/dynamic';
import { fetchStaticResponse } from 'features/details/response/static';
import { SocketIo } from 'classes/socketIo.class';
import { DEVICE_REGISTRATION_API_BASE_URL } from 'config';
import { accessTokenSelector } from 'features/identity/auth';
import { fetchAlarmHelpers, helpers } from './helpers';
import { response } from './response';
import { fetchMapOverlay, map } from './map';
import { chat, fetchAlarmEvents } from './chat';

import {
  setProcedurePriority, updateCurrentPosition, updateAlarm, updateAlarmAsset,
} from './exports';
import { dcc, fetchDcc } from './dcc';
import { ack } from './ack';

export * from './exports';

const name = 'details';

export type FetchAlarmDetailsArgs = AbortableThunkArg & {
  alarmId: string;
};

export const fetchAlarmDetails = createThunk<AlarmDetailsResult, FetchAlarmDetailsArgs>({
  name: `${name}/fetchAlarmDetails`,
  handler: async ({ alarmId, controller: { signal } }, { dispatch, getState }): Promise<AlarmDetailsResult> => {

    dispatch(fetchAlarmEvents({
      alarmId,
      controller: childController(signal),
      paginate: false,
    }));

    dispatch(fetchAlarmHelpers({
      alarmId,
      controller: childController(signal),
    }));

    const deviceRegistrationWs = new SocketIo(signal);
    await deviceRegistrationWs.connect(`${DEVICE_REGISTRATION_API_BASE_URL}?x-auth-token=${accessTokenSelector(getState())}`);

    const { data: alarm } = await api.get<PopulatedAlarm>(`/alarms/${alarmId}`, {
      signal,
    });

    dispatch(fetchMapOverlay({
      alarm,
      controller: childController(signal),
    }));

    if (!alarm.ack.value) {

      dispatch(fetchDcc({
        controller: childController(signal),
        ws: deviceRegistrationWs,
        alarm,
        startMuted: alarm.severity === AlarmSeverity.Red,
      }));

      dispatch(fetchDynamicResponse({
        alarmId,
        controller: childController(signal),
      }));

      dispatch(fetchStaticResponse({
        alarmId,
        controller: childController(signal),
      }));

    }

    return {
      alarm,
    };

  },
});

export const getLocalAlarmPreferences = (alarmId: string): AlarmPreferences => {

  const record = getLocal('alarmPreferences', {});

  return record[alarmId] ?? {
    procedurePriority: 'verification',
    response: {},
  };

};

export const setLocalAlarmPreferences = (alarmId: string, preferences: AlarmPreferences): void => {

  const record = getLocal('alarmPreferences', {});

  setLocal('alarmPreferences', {
    ...record,
    [alarmId]: preferences,
  });

};

export const details = createSlice<AlarmDetailsState, typeof name>({
  name,
  initialState: {
    ...asyncPollableDefaults,
    alarmId: null,
    preferences: null,
  },
  childSlices: {
    map,
    helpers,
    chat,
    response,
    dcc,
    ack,
  },
  extraReducers: (builder, initialState) => builder
    .addCase(updateAlarm, (state, action) => {

      state.ack.value = action.payload.ack;
      state.value.alarm = action.payload;

    })
    .addCase(updateAlarmAsset, (state, { payload }) => {

      const alarm = state.value?.alarm;

      if (alarm?.asset?._id === payload._id && !alarm?.ack?.value) {

        state.value.alarm.asset = payload;

      }

    })
    .addCase(setProcedurePriority, (state, action) => {

      state.preferences.procedurePriority = action.payload;

      setLocalAlarmPreferences(state.alarmId, state.preferences);

    })
    .addCase(updateCurrentPosition, (state, action) => {

      state.value.alarm.asset.position = action.payload;

    })
    .addCase(fetchAlarmDetails.pending, (state, action) => {

      state.controller?.abort();

      for (const property in initialState) {

        state[property] = initialState[property];

      }

      withPollablePending(state, action);

      state.alarmId = action.meta.arg.alarmId;
      state.preferences = getLocalAlarmPreferences(action.meta.arg.alarmId);

    })
    .addCase(fetchAlarmDetails.fulfilled, (state, action) => {

      withPollableFulfilled(state, action);

      state.ack.value = action.payload.alarm.ack;

      /* TODO: Frozen alarm info
      const frozenInfo = alarm.meta?.frozenAlarmInfo;

      const setFrozenInfo = <T>(stateSlice: Draft<AsyncPollableState<T>> & { missing: boolean }, result: T) => {

        if (result) {

          stateSlice.status = AsyncStatus.Fulfilled;
          stateSlice.realStatus = AsyncStatus.Fulfilled;
          stateSlice.value = result as Draft<T>;
          stateSlice.missing = false;

        } else {

          stateSlice.missing = true;
          state.displayOldAMNotice = true;

        }

      };

      setFrozenInfo(state.response.static, frozenInfo?.responseStatic?.result);
      setFrozenInfo(state.response.dynamic, frozenInfo?.responseDynamic?.result);
      setFrozenInfo(state.helpers, frozenInfo?.helpers?.result);
       */

    })
    .addCase(fetchAlarmDetails.rejected, withPollableRejected)
    .addCase(fetchAlarmDetails.retrying, withPollableRetrying),
});
