import { DbOperations } from '@x-guard/xgac-types/xgac';
import { UUID } from '@x-guard/xgac-types/utils';
import { v4 } from 'uuid';
import { SafeWebSocket } from 'classes/safeWebSocket.class';

export type OutgoingSubscriptionMessageData = {
  events: DbOperations[];
  entity: string;
  customers?: UUID[];
  alarmCenter?: UUID;
  id?: UUID[];
  filter?: object;
};

export type IncomingEntityMessage<Document = any> = {
  operation: DbOperations;
  entity: string;
  document: Document;
  topic: string;
  partial: boolean;
  autoSubscribe: string[];
};

type IncomingEvent<Event extends string> = {
  event: Event;
  requestId: string;
};

export type IncomingSubscriptionError = IncomingEvent<'error'> & {
  data: {
    code: number;
    message: string;
  };
  extra: any;
};

export type IncomingSubscriptionMessage = IncomingEvent<'subscribed'> & {
  data: {
    events: DbOperations[];
    topics: string[];
  };
};

type Subscription = {
  topics: string[];
  handler: SubscriptionHandler<unknown>;
};

export type SubscriptionHandlerOperationRouter<T> = {
  [Op in DbOperations]?: (data: T) => void;
};

export type SubscriptionHandlerRouter<T> = {
  [key: string]: ((data: T) => void) | SubscriptionHandlerOperationRouter<T>;
};

export type SubscriptionHandler<T> = ((data: T) => void) | SubscriptionHandlerRouter<T>;

export type SubscriptionOptions<T> = {
  data: OutgoingSubscriptionMessageData;
  signal: AbortSignal;
  handler: SubscriptionHandler<T>;
};

export type PromiseExecutor = [
  resolve: (value: any) => void,
  reject: (reason?: any) => void,
];

export class XGACWebSocket extends SafeWebSocket {

  private promiseExecutorsByRequestId: Record<string, PromiseExecutor> = {};

  private subscriptionsByTopic: Record<string, Subscription[]> = {};

  private accessToken: string = null;

  public constructor(signal: AbortSignal) {

    super();

    signal.addEventListener('abort', () => {

      this.disposed = true;
      this.disconnect();

    });

    this.on('message', (message: any) => {

      if (message.requestId) {

        this.onRequestId(message);

      }

      if (message.topic) {

        this.onTopic(message);

      }

      if (message.autoSubscribe && message.autoSubscribe.length > 0) {

        this.onAutoSubscribe(message);

      }

    });

  }

  public setAccessToken(accessToken: string) {

    this.accessToken = accessToken;

  }

  private onRequestId(body: any) {

    const requestId = body.requestId;

    const executor = this.promiseExecutorsByRequestId[requestId];

    delete this.promiseExecutorsByRequestId[requestId];

    if (!executor) {

      console.log(`No promise executor found for requestId: ${requestId}`);
      return;

    }

    executor[0](body);

  }

  private onTopic(body: IncomingEntityMessage) {

    this.subscriptionsByTopic[body.topic]?.forEach(({ handler }) => {

      const getHandler = () => {

        // Early return if handler is a function
        if (typeof handler === 'function') return handler;

        // Get handler router
        const router = handler[body.entity];

        // Early return if router is a function
        if (typeof router === 'function') return router;

        // Get handler
        return router?.[body.operation];

      };

      getHandler()?.(body);

    });

  }

  private onAutoSubscribe(body: IncomingEntityMessage) {

    // Get subscriptions related to the event
    const subscriptions = this.subscriptionsByTopic[body.topic];

    // Add the auto-subscribed topics to the subscriptions
    subscriptions.forEach((subscription) => {

      subscription.topics.push(...body.autoSubscribe);

      this.addTopics(subscription);

    });

  }

  public async subscribe<T>({ data, handler, signal }: SubscriptionOptions<T>): Promise<Subscription> {

    const response = await this.sendMessage<IncomingSubscriptionMessage | IncomingSubscriptionError>('subscribe', data);

    if (response.event === 'error') {

      throw new Error(JSON.stringify({
        data: response.data,
        extra: response.extra,
      }));

    }

    const subscription: Subscription = {
      topics: response.data.topics,
      handler,
    };

    this.addTopics(subscription);

    const unsubscribe = () => {

      if (this.disposed) return;

      const removedTopics = this.removeTopics(subscription);

      this.sendMessage('unsubscribe', { topics: removedTopics });

    };

    if (signal.aborted) {

      unsubscribe();

    } else {

      signal.addEventListener('abort', unsubscribe);

    }

    return subscription;

  }

  // Returns added topics
  private addTopics(subscription: Subscription): string[] {

    const added = [];

    subscription.topics.forEach((topic) => {

      this.subscriptionsByTopic[topic] ??= [];

      if (!this.subscriptionsByTopic[topic].includes(subscription)) {

        this.subscriptionsByTopic[topic].push(subscription);
        added.push(topic);

      }

    });

    return added;

  }

  // Returns removed topics
  private removeTopics(subscription: Subscription): string[] {

    const removed = [];

    subscription.topics.forEach((topic) => {

      const index = this.subscriptionsByTopic[topic]?.indexOf(subscription);

      if (index !== undefined) {

        this.subscriptionsByTopic[topic].splice(index, 1);

        // Clear empty arrays
        if (this.subscriptionsByTopic[topic].length === 0) {

          removed.push(topic);
          delete this.subscriptionsByTopic[topic];

        }

      }

    });

    return removed;

  }

  private async sendMessage<T>(event: string, data: any): Promise<T> {

    if (!this.accessToken) {

      throw new Error('No access token set');

    }

    return new Promise((resolve, reject) => {

      const requestId = v4();

      this.send({
        requestId,
        token: this.accessToken,
        event,
        data,
      });

      this.promiseExecutorsByRequestId[requestId] = [resolve, reject];

    });

  }

}
