import {
  AbortableThunkArg,
  DynamicResponseState, RS,
} from 'types';
import {
  createSlice,
  asyncPollableDefaults,
  withPollableFulfilled,
  withPollablePending,
  withPollableRejected,
  createThunk,
  defaultRetryConfig,
  withPollableRetrying,
  api,
} from 'lib';
import { ResponseGroup } from '@x-guard/xgac-types/xgac';

const name = 'details/response/dynamic';

export type FetchDynamicResponseArgs = AbortableThunkArg & {
  alarmId: string;
};

export const fetchDynamicResponse = createThunk<ResponseGroup[], FetchDynamicResponseArgs>({
  name,
  handler: async ({ alarmId, controller: { signal } }) => {

    const { data } = await api.get<{ result: ResponseGroup[] }>(`/alarms/${alarmId}/response/dynamic`, {
      signal,
    });

    return data.result;

  },
  retryConfig: defaultRetryConfig,
});

export const dynamicResponse = createSlice<DynamicResponseState, typeof name>({
  name,
  initialState: {
    ...asyncPollableDefaults,
  },
  extraReducers: (builder) => builder
    .addCase(fetchDynamicResponse.pending, withPollablePending)
    .addCase(fetchDynamicResponse.fulfilled, withPollableFulfilled)
    .addCase(fetchDynamicResponse.rejected, withPollableRejected)
    .addCase(fetchDynamicResponse.retrying, withPollableRetrying),
});

export const dynamicResponseStatusSelector = (state: RS) => state.details.response.dynamic.status;
export const dynamicResponseValueSelector = (state: RS) => state.details.response.dynamic.value;
export const dynamicResponseSelector = (state: RS) => state.details.response.dynamic;
