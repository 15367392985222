import React, {
  useCallback, useMemo, useState, FC,
} from 'react';

import { ContactableConfig } from 'types';
import { alarmContext } from './alarmContext';

export const ProvideAlarmContext: FC = ({ children }) => {

  const [showContact, setShowContact] = useState<boolean>(false);
  const [contactConfig, setContactConfig] = useState<ContactableConfig>(null);

  // Disables the contact imageOverlays
  const onDismissContact = useCallback(() => {

    setShowContact(false);

  }, [setShowContact]);

  // Enables the contact imageOverlays for a given contact config
  const onClickContact = useCallback((config: ContactableConfig) => {

    setShowContact(true);
    setContactConfig(config);

  }, [setShowContact, setContactConfig]);

  const [enableAckForm, setEnableAckForm] = useState<boolean>(false);

  // Enables the alarm acknowledgement imageOverlays
  const onRequestAckForm = useCallback(() => {

    setEnableAckForm(true);

  }, [setEnableAckForm]);

  // Disables the alarm acknowledgement imageOverlays
  const onDismissAckForm = useCallback(() => {

    setEnableAckForm(false);

  }, [setEnableAckForm]);

  const value = useMemo(() => ({
    showContact,
    onClickContact,
    onDismissContact,
    contact: contactConfig,
    enableAckForm,
    onRequestAckForm,
    onDismissAckForm,
  }), [
    showContact,
    onClickContact,
    onDismissContact,
    contactConfig,
    enableAckForm,
    onRequestAckForm,
    onDismissAckForm,
  ]);

  return (
    <alarmContext.Provider value={value}>
      {children}
    </alarmContext.Provider>
  );

};
