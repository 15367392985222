import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { openAlarmsSelector } from 'features/overview/openAlarms';
import { useLocale } from 'lib';
import { toast } from 'react-toastify';
import { blockNotificationsSelector } from 'features/identity/preferences';
import { useNavigate } from 'react-router-dom';
import { AlarmSeverity } from '@x-guard/xgac-types/xgac';

let shownError = false;

// Creates an interval that will push a notification with the given text every 7.5 seconds
const notify = (text: string, onClick?: () => void) => {

  const notifications: Notification[] = [];

  const showNotification = () => {

    const notification = new Notification(text);

    if (onClick) {

      notification.onclick = onClick;

    }

    notifications.push(notification);

    // Prevent memory leak
    if (notifications.length > 5) {

      notifications.shift().close?.();

    }

  };

  const intervalId = setInterval(showNotification, 7500);

  showNotification();

  // Cleanup, closes all notifications and clears the interval
  return () => {

    for (const notification of notifications) {

      notification.close?.();

    }

    clearInterval(intervalId);

  };

};

/**
 * Displays notifications for new alarms
 */
export const useAlarmNotifications = (): void => {

  const { t } = useLocale();

  const openAlarms = useSelector(openAlarmsSelector);
  const blockNotifications = useSelector(blockNotificationsSelector);
  const navigate = useNavigate();

  useEffect(() => {

    const requestPermission = async () => {

      const response = await Notification.requestPermission();

      if (response !== 'granted' && !shownError) {

        shownError = true;
        toast.error(t('Permission.Notification.Not_Granted'));

      }

    };

    if (Notification.permission !== 'granted') {

      requestPermission();

    }
    // omit 't' from dependencies, as this effect should run just once

  }, []); // eslint-disable-line

  useEffect(() => {

    if (blockNotifications) return undefined;

    if (openAlarms.length === 1) {

      const [alarm] = openAlarms;

      const title = {
        [AlarmSeverity.Red]: t('Notification.New_Alarm.Panic'),
        [AlarmSeverity.Orange]: t('Notification.New_Alarm.Assistance'),
        [AlarmSeverity.Green]: t('Notification.New_Alarm.Test'),
      }[alarm.severity];

      return notify(title, () => {

        window.focus();
        navigate(`alarms/${alarm._id}`);

      });

    }

    if (openAlarms.length > 1) {

      return notify(t('Notification.Multiple_New_Alarms'), () => {

        window.focus();

      });

    }

    return undefined;

    // omit 't' as changes in locale shouldn't trigger this effect

  }, [blockNotifications, openAlarms]); // eslint-disable-line

};
