import React, {
  FC, HTMLProps, MouseEvent, MouseEventHandler, MutableRefObject, ReactNode,
} from 'react';
import cx from 'clsx';
import sanitize from 'sanitize-html';

import { Icon } from '@mdi/react';

import { stopPropagation } from 'lib';
import { mdiClose } from '@mdi/js';
import styles from './style.module.scss';

export type ChipVariant =
  | 'warning100'
  | 'warning200'
  | 'light100'
  | 'light200'
  | 'medium100'
  | 'dark'
  | 'primary-reversed'
  | 'tertiary'
  | 'primary'
  | 'green400'
  | 'green300'
  | 'green250'
  | 'green250-muted'
  | 'green200'
  | 'green100'
  | 'danger';

export type ChipSize = 'small' | 'medium' | 'large' | 'largest';

export type NodeOrFn = ReactNode | (() => ReactNode);

export type ChipProps = {
  label: NodeOrFn;
  passRef?: MutableRefObject<HTMLDivElement>;
  className?: string;
  variant?: ChipVariant;
  size?: ChipSize;
  onClick?: MouseEventHandler;
  onDelete?: () => void;
  disabled?: boolean;
  flex?: boolean;
  htmlTag?: string;
  extraProps?: HTMLProps<HTMLElement>;
};

export const Label: FC<{ label: NodeOrFn }> = ({ label }) => {

  if (typeof label === 'function') {

    return label();

  }

  if (typeof label === 'string') {

    return <span dangerouslySetInnerHTML={{ __html: sanitize(label) }}/>;

  }

  return label;

};

export const Chip: FC<ChipProps> = ({
  label,
  variant = 'light200',
  size = 'small',
  onDelete = null,
  onClick = null,
  className = null,
  passRef = null,
  htmlTag = 'div',
  extraProps = {},
  disabled = false,
  flex = false,
}) => {

  // Wrapped on delete. Prevents the mouse click event from bubbling up
  const handleOnDelete = (onDelete && !disabled) ? (e: MouseEvent<HTMLSpanElement>) => {

    e.preventDefault();
    e.stopPropagation();
    onDelete();

  } : null;

  return React.createElement(
    htmlTag,
    {
      ref: passRef,
      className: cx(
        className,
        styles.chipWrapper,
        styles[variant],
        styles[size],
        flex && styles.flex,
        onClick === null && styles.noSelect,
        (onClick && !disabled) && styles.clickable,
        disabled && styles.disabled,
      ),
      onClick: (onClick && !disabled) ? onClick : stopPropagation,
      ...extraProps,
    }, (
      <>
        <Label label={label}/>
        {onDelete && (
          <span className={styles.icon} onClick={handleOnDelete}>
            <Icon path={mdiClose}/>
          </span>
        )}
      </>
    ),
  );

};
