import React, {
  FC, memo, useMemo,
} from 'react';

import { useLocale } from 'lib';
import { Responder, ResponseGroup, ResponseGroupType } from '@x-guard/xgac-types/xgac';
import { ContactableSubsection } from '../contactableSubsection';
import { Section } from '../section';
import { Label } from '../label';
import { Title } from '../title';

type GroupTitleProps = {
  group: ResponseGroup;
};

type ResponseSectionGroupProps = {
  group: ResponseGroup;
  className: string;
};

const GroupTitle: FC<GroupTitleProps> = ({ group }) => {

  const { t } = useLocale();

  if (group.type === ResponseGroupType.Nearby) {

    return (
      <>
        <Title
          variant={'green200'}
          text={t('Alarm_Detail_Response.Type.ACR_QR.Title')}
        />
        <Label text={t('Alarm_Detail_Response.Type.ACR_QR.Subtitle')}/>
      </>
    );

  }

  if (group.type === ResponseGroupType.ResponsePartners) {

    return (
      <>
        <Title
          variant={'green400'}
          text={t('Alarm_Detail_Response.Type.ACR_NQR.Title')}
        />
        <Label text={t('Alarm_Detail_Response.Type.ACR_NQR.Subtitle')}/>
      </>
    );

  }

  if (group.type === ResponseGroupType.Zone) {

    return (
      <>
        <Title
          variant={'green400'}
          text={t('Alarm_Detail_Response.Type.ACR_RA.Title')}
        />
        <Label text={t('Alarm_Detail_Response.Type.ACR_RA.Subtitle', { code: group.zone.name })}/>
      </>
    );

  }

  if (group.type === ResponseGroupType.Police) {

    return (
      <Title
        variant={'primary'}
        text={t('Alarm_Detail_Response.Type.ACR_PD.Title')}
      />
    );

  }

  if (group.type === ResponseGroupType.Personal) {

    return (
      <Title
        variant={'green250'}
        text={t('Alarm_Detail_Response.Type.ACR_PC.Title')}
      />
    );

  }

  throw new Error(`Unknown group type: ${group.type}`);

};

export const ResponseSectionGroup = memo<ResponseSectionGroupProps>(({ group, className }) => {

  const responders = useMemo<Responder[]>(() => {

    /* TODO: Verify we even need this
    if (currentPosition) {
      return orderBy(group.contacts, (contact) => hasPosition(contact)
        ? getDistance(contact.position, currentPosition)
        : Number.MAX_VALUE
      );
    } */

    return group.responders;

  }, [group.responders]);

  return (
    <Section
      title={<GroupTitle group={group} />}
      className={className}
    >
      {responders.map((responder, index) => (
        <ContactableSubsection
          type={group.type}
          key={`${group.type}-${index}`}
          responder={responder}
        />
      ))}
    </Section>
  );

});
