import React, {
  FC, useCallback, useContext, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCheckedSelector, setChecked } from 'features/details/response';
import { setFocusedPosition } from 'features/details/map';
import { Responder, ResponseGroupType } from '@x-guard/xgac-types/xgac';
import styles from './style.module.scss';

import { ContactChip } from '../contactChip';
import { SubsectionLayout } from '../subsectionLayout';
import { ContactSwitch } from '../contactSwitch';
import { alarmContext } from '../../alarmContext';
import { getResponderCanBeContacted } from '../../contact/contactOptions';
import { DistanceLabel } from './distanceLabel';

type ContactableSubsectionProps = {
  responder: Responder;
  type?: ResponseGroupType;
  hideDistanceLabel?: boolean;
  hideContactChip?: boolean;
};

/**
 * Represents the section for a contactable (Like an asset or static / dynamic response)
 */
export const ContactableSubsection: FC<ContactableSubsectionProps> = ({
  responder,
  type,
  hideDistanceLabel,
  hideContactChip,
}) => {

  const {
    onClickContact,
  } = useContext(alarmContext);

  const dispatch = useDispatch();

  const checked = useSelector(getCheckedSelector(responder));

  const dispatchSetChecked = useCallback((value = true) => {

    dispatch(setChecked({
      responder,
      checked: value,
    }));

  }, [responder, dispatch]);

  const canBeContacted = useMemo(() => {

    return getResponderCanBeContacted(responder);

  }, [responder]);

  const onClickContactSwitch = useCallback((contactType) => {

    onClickContact({
      responder,
      contactType,
      onContactConfirmation: dispatchSetChecked,
    });

  }, [dispatchSetChecked, onClickContact, responder]);

  const onClick = useCallback(() => {

    const trackable = [ResponseGroupType.Nearby, ResponseGroupType.ResponsePartners].includes(type) || !type;

    if (trackable && checked && responder.info.position) {

      dispatch(setFocusedPosition(responder.info.position.coordinates));

    }

  }, [type, responder, checked, dispatch]);

  return (
    <SubsectionLayout
      onClick={onClick}
      left={(
        <>
          <ContactSwitch
            checked={checked}
            setChecked={dispatchSetChecked}
          />
          <div className={styles.name}>
            {responder.info.name}
          </div>
        </>
      )}
      right={(
        <>
          {(!hideContactChip && canBeContacted) ? (
            <ContactChip
              contactType={type === ResponseGroupType.Police ? 'phoneCall' : null}
              onClick={onClickContactSwitch}
            />
          ) : null}
          {(!hideDistanceLabel && responder.info.position) ? (
            <DistanceLabel position={responder.info.position}/>
          ) : null}
        </>
      )}
    />
  );

};
