import { useDispatch, useSelector } from 'react-redux';
import { alarmIdSelector, updateAlarm, updateAlarmAsset } from 'features/details';
import { childController, CustomerContext, useWebSocketContext } from 'lib';
import { useEffect } from 'react';
import { Asset, DbOperations } from '@x-guard/xgac-types/xgac';
import { IncomingEntityMessage } from 'classes/xgacWebSocket';
import { appendMessage } from 'features/details/chat';
import {
  AnyMessage, PopulatedAlarm, PopulatedAlarmHelper,
} from 'types';
import { userContextSelector } from 'features/identity/auth';
import { addHelper, updateHelper } from 'features/details/helpers';
import { PayloadActionCreator } from '@reduxjs/toolkit';

export const useAlarmDetailsSubscription = ({ signal }: CustomerContext) => {

  const alarmId = useSelector(alarmIdSelector);
  const ws = useWebSocketContext();
  const dispatch = useDispatch();
  const userCtx = useSelector(userContextSelector);

  useEffect(() => {

    if (!alarmId || !ws) return undefined;

    const controller = childController(signal);

    const dispatcher = <T>(actionCreator: PayloadActionCreator<T, string>) => (message: IncomingEntityMessage<T>) => {

      dispatch(actionCreator(message.document));

    };

    ws.subscribe<IncomingEntityMessage>({
      signal: controller.signal,
      data: {
        entity: 'Alarm',
        events: [
          DbOperations.Create,
          DbOperations.Update,
        ],
        id: [alarmId],
      },
      handler: {
        Asset: {
          [DbOperations.Update]: dispatcher<Asset>(updateAlarmAsset),
        },
        AlarmHelper: {
          [DbOperations.Update]: dispatcher<PopulatedAlarmHelper>(updateHelper),
          [DbOperations.Create]: dispatcher<PopulatedAlarmHelper>(addHelper),
        },
        AlarmEvent: {
          [DbOperations.Create]: ({ document }: IncomingEntityMessage<AnyMessage>) => {

            if (document.user._id === userCtx.user._id) return;

            dispatch(appendMessage(document));

          },
        },
        Alarm: {
          [DbOperations.Update]: dispatcher<PopulatedAlarm>(updateAlarm),
        },
      },
    });

    return () => controller.abort();

  }, [alarmId, ws, dispatch, userCtx, signal]);

};
