import { createAction, createSelector } from '@reduxjs/toolkit';
import {
  AsyncStatus, CustomerNode, S, AnyStoredToken, UserContext, StoredToken,
} from 'types';

export const removeStoredToken = createAction<StoredToken>('identity/auth/removeStoredToken');
export const logout = createAction('shared/logout');

export const statusSelector: S<AsyncStatus> = (state) => state.identity.auth.status;
export const authenticatedSelector: S<boolean> = (state) => state.identity.auth.status.fulfilled;
export const accessTokenSelector: S<string> = (state) => state.identity.auth.value?.login?.accessToken;
export const refreshTokenSelector: S<string> = (state) => state.identity.auth.value?.login?.refreshToken;
export const userContextSelector: S<UserContext> = (state) => state.identity.auth.value?.context;
export const userIdSelector: S<string> = (state) => state.identity.auth.value.context.user._id;
export const storedTokensSelector: S<AnyStoredToken[]> = (state) => state.identity.auth.storedTokens;
export const customersSelector: S<CustomerNode[]> = createSelector(
  userContextSelector,
  (context) => context?.customerTreeFlat ?? [],
);
export const multipleCustomersExistsSelector: S<boolean> = createSelector(
  customersSelector,
  (context) => context.length > 1,
);
