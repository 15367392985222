import { createContext } from 'react';

import { ContactableConfig } from 'types';
import { noop } from 'lib';

export type AlarmContext = {
  onClickContact: (config: ContactableConfig) => void;
  showContact: boolean;
  onDismissContact: () => void;
  contact: ContactableConfig;
  enableAckForm: boolean;
  onRequestAckForm: () => void;
  onDismissAckForm: () => void;
};

export const alarmContext = createContext<AlarmContext>({
  onClickContact: noop,
  showContact: false,
  onDismissContact: noop,
  contact: null,
  enableAckForm: false,
  onRequestAckForm: noop,
  onDismissAckForm: noop,
});
