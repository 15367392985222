import React, { useMemo } from 'react';

import { useLocale, useLogOperatorAction } from 'lib';
import { SplitChipButtonProps, Text } from 'components/atoms';
import { useDispatch, useSelector } from 'react-redux';
import { procedurePrioritySelector, setProcedurePriority } from 'features/details';
import { ProcedurePriority } from 'types';
import { logOperatorAction } from 'features/details/chat';
import { ackedSelector } from 'features/details/ack/exports';
import styles from './style.module.scss';

/**
 * Wraps presentation logic for a split chip button that changes the verification
 */
export const useProcedurePriority = (): SplitChipButtonProps => {

  const { t } = useLocale();

  const dispatch = useDispatch();
  const currentPriority = useSelector(procedurePrioritySelector);
  const acked = useSelector(ackedSelector);
  const logAction = useLogOperatorAction(logOperatorAction, ackedSelector);

  return useMemo(() => {

    const handleOnClick = (priority: ProcedurePriority) => {

      if (currentPriority === priority) {

        return null;

      }

      return () => {

        logAction(priority === 'verification'
          ? 'ACTION_PROTOCOL_VERIFICATION'
          : 'ACTION_PROTOCOL_HELP');

        dispatch(setProcedurePriority(priority));

      };

    };

    return {
      className: styles.splitChipButton,
      left: {
        label: (
          <Text variant={'boldSpan'} theme={currentPriority === 'verification' ? 'dark' : 'light'}>
            {t('Alarm_Detail_Response_Procedure.Priority.Verification')}
          </Text>
        ),
        variant: currentPriority === 'verification' ? 'warning200' : 'light200',
        onClick: handleOnClick('verification'),
        disabled: acked,
      },
      right: {
        label: (
          <Text variant={'boldSpan'} theme={currentPriority === 'help' ? 'dark' : 'light'}>
            {t('Alarm_Detail_Response_Procedure.Priority.Help')}
          </Text>
        ),
        variant: currentPriority === 'help' ? 'danger' : 'light200',
        onClick: handleOnClick('help'),
        disabled: acked,
      },
    };

  }, [currentPriority, dispatch, acked, logAction, t]);

};
