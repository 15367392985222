import { useCallback, useMemo, useState } from 'react';

import FuzzySearch from 'fuzzy-search';

import { useDispatch, useSelector } from 'react-redux';
import { ChipVariant } from 'components/atoms';
import {
  ChipArrayProps,
  FilterableDropdownControlProps,
  DropdownControlValueMeta,
  GetChipProps,
} from 'components/molecules';
import { find } from 'lodash';
import { useToggle } from 'lib';
import { customersSelector, userIdSelector } from 'features/identity/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { Customer } from '@x-guard/xgac-types/xgac';
import { CustomerNode } from 'types';
import { connectedCustomerSelector, usedCustomersNodeSelector } from 'features/identity';
import { removeUsedCustomer, setCustomer } from 'features/identity/preferences';
import { NoCustomersFound } from './noCustomersFound';

const getCustomerMeta = (customer: Customer | CustomerNode): DropdownControlValueMeta => ({
  key: customer._id,
  label: customer.name,
});

type UseCustomersReturn = {
  dropdownProps: Omit<FilterableDropdownControlProps<CustomerNode>, 'placeholder'>;
  usedCustomersProps: ChipArrayProps<CustomerNode>;
};

/**
 * Hook used to manage the state for a dropdown of customers
 */
export const useCustomers = (onCustomerSelected: () => void, initiallyOpen: boolean): UseCustomersReturn => {

  const dispatch = useDispatch();
  const selectedCustomerNode = useSelector(connectedCustomerSelector);
  const userId = useSelector(userIdSelector);
  const customers = useSelector(customersSelector);
  const usedCustomers = useSelector(usedCustomersNodeSelector);
  const navigate = useNavigate();
  const location = useLocation();

  // Stable identity for customer setter
  const onSelectCustomer = useCallback((node: CustomerNode) => {

    // Go back to the alarms overview if we haven't already
    if (location.pathname !== '/alarms') {

      navigate('/alarms');

    }

    dispatch(setCustomer({ node, userId }));

    onCustomerSelected();

  }, [dispatch, onCustomerSelected, location, navigate, userId]);

  const openState = useToggle(initiallyOpen);
  const [filter, setFilter] = useState<string>(null);

  // Memoize filtered customers
  const filteredCustomers = useMemo(() => {

    if (filter === null) return customers;

    const searcher = new FuzzySearch(customers, ['name'], {
      caseSensitive: false,
      sort: true,
    });

    return searcher.search(filter);

  }, [filter, customers]);

  const getChipProps: GetChipProps<CustomerNode> = useCallback((value) => ({
    ...getCustomerMeta(value),
    variant: value._id === selectedCustomerNode._id ? 'dark' : 'light200' as ChipVariant,
    onClick: () => {

      const selectedCustomer = find(customers, { _id: value._id });

      if (selectedCustomer._id !== selectedCustomerNode._id) {

        onSelectCustomer(selectedCustomer);

      }

    },
    onDelete: () => {

      dispatch(removeUsedCustomer({ node: value, userId }));

    },
  }), [onSelectCustomer, customers, selectedCustomerNode, dispatch, userId]);

  return {
    dropdownProps: {
      value: selectedCustomerNode,
      values: filteredCustomers,
      onSelectValue: onSelectCustomer,
      getMeta: getCustomerMeta,
      filter: setFilter,
      noResults: NoCustomersFound,
      openState,
    },
    usedCustomersProps: {
      values: usedCustomers,
      getChipProps,
    },
  };

};
