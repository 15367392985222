import React, { FC } from 'react';
import cx from 'clsx';

import {
  Navigate, Route, Routes,
} from 'react-router-dom';
import { ImageOverlay } from 'components/organisms';
import { ToastContainer } from 'react-toastify';
import { qs } from 'lib';
import { AlarmSwitchGuard } from './authenticated/alarmSwitch';
import {
  LoginPage, Login, AuthCallback,
} from './unauthenticated';
import { Content } from './content';
import styles from './style.module.scss';
import { useAuthRefresh } from './useAuthRefresh';
import { useAutoLogin } from './useAutoLogin';

type RootSwitchProps = {
  hide: boolean;
};

export const RootSwitch: FC<RootSwitchProps> = ({ hide }) => {

  useAutoLogin();
  useAuthRefresh();

  if (document.location.hash.startsWith('#/secure/content/alarm-manager-asset/')) {

    const parts = document.location.hash.split('/');
    const assetId = parts[parts.length - 1];
    const path = `/alarms?${qs({ toAlarmOfAsset: assetId })}`;

    return <Navigate to={path} replace={true} />;

  }

  return (
    <div className={cx(styles.contentWrapper, hide && styles.hide)}>
      <ToastContainer/>
      <ImageOverlay>
        <Content>
          {/* TODO: Add 'QueryToken' login component */}
          <Routes>
            {/* Login screen */}
            <Route element={<LoginPage/>}>
              <Route path='/' element={<Login/>}/>
              <Route path='/auth/callback' element={<AuthCallback/>}/>
            </Route>
            <Route path='/alarms/*' element={<AlarmSwitchGuard/>}/>
            <Route path='*' element={<Navigate to={'/'}/>}/>
          </Routes>
        </Content>
      </ImageOverlay>
    </div>
  );

};
