import moment from 'moment';

import {
  AnyDisplayableMessage,
  AnyDisplayableMessageProps,
  AnyMessage,
  MessageImageMode,
  MessageSide, UserContext,
} from 'types';
import { isOwnMessage } from 'lib';
import { clone, cloneDeep } from 'lodash';

const isMergeable = ({ type, action }: Omit<AnyMessage, 'id'>): boolean => {

  return type !== 'system' && !(type === 'action' && action === 'ACTION_ALARM_ACKNOWLEDGE');

};

export const xgServiceAccounts = [
  'xgdeploymentbot',
  'xguard_ats',
  'sa-xgac-node-ats',
  'sa-xgac-legacy-main',
  'sa-xgac-node-main-api',
];

/**
 * HOC used to select messages that can be displayed, by filtering out hidden ones.
 * Also sets some properties on the messages
 * Coalesces to null
 */
export const getMessagesSelector = (userContext: UserContext, acked: boolean) => (result: AnyMessage[]): AnyDisplayableMessageProps[] => {

  // No messages loaded
  if (result === null) {

    return null;

  }

  // Filter messages to displayable messages
  const filteredMessages = (result
    .filter((message) => message.type !== 'hidden') as AnyDisplayableMessage[])
    .map(clone);

  /**
   * Evaluates whether two messages can be merged together
   */
  const evalDiff = (fromMessage: AnyMessage, toMessage: AnyMessage): boolean => {

    // Cannot be merged if any of the messages don't exist
    if (fromMessage === null || toMessage === null) {

      return false;

    }

    // Cannot be merged if the messages were sent by different users
    const getId = (message: AnyMessage): string => `${message.user._id}/${message.meta?.name}`;

    if (getId(fromMessage) !== getId(toMessage)) {

      return false;

    }

    if (!isMergeable(fromMessage) || !isMergeable(toMessage)) {

      return false;

    }

    const from = moment(fromMessage.createdAt);
    const to = moment(toMessage.createdAt);

    // Cannot be merged if the messages were posted on a different day
    if (!from.isSame(to, 'day')) {

      return false;

    }

    // Can be merged if the messages were posted within a 10 minute interval
    return moment.duration(from.diff(to)).asMinutes() < 10;

  };

  // Map over messages
  return filteredMessages.map<AnyDisplayableMessageProps>((message, idx) => {

    message = cloneDeep(message);

    const {
      type, createdAt, action, meta, user,
    } = message;

    const hasTop = idx < filteredMessages.length - 1;
    const hasBottom = idx > 0;
    const curr = moment(createdAt);

    let side: MessageSide = 'center';
    let joinWithTop = false;
    let joinWithBottom = false;
    let imageMode = MessageImageMode.None;

    const isOwn = isOwnMessage(message, userContext);

    if (type !== 'user' && xgServiceAccounts.includes(user.name)) {

      message.user.name = 'X-Guard';

    }

    if (type === 'system' && action === 'ACTION_CHAT' && meta?.prefixDisplayName && !isOwn) {

      side = 'left';
      message.user.name = meta.prefixDisplayName as string;

    }

    // Only allow merging of messages for non system and non alarm acknowledge messages
    if (type !== 'system' && !(type === 'action' && action === 'ACTION_ALARM_ACKNOWLEDGE')) {

      side = isOwn ? 'right' : 'left';

      joinWithTop = evalDiff(message, hasTop ? filteredMessages[idx + 1] : null);
      joinWithBottom = evalDiff(message, hasBottom ? filteredMessages[idx - 1] : null);

      if (user.properties?.image) {

        imageMode = joinWithBottom ? MessageImageMode.Margin : MessageImageMode.Display;

      }

    }

    return {
      content: message,
      imageMode,
      isFirstInDay: !hasTop || !curr.isSame(moment(filteredMessages[idx + 1].createdAt), 'day'),
      joinWithTop,
      joinWithBottom,
      side,
      bottomMargin: !hasBottom && !acked,
    };

  });

};
