import React, { FC, memo, useMemo } from 'react';

import { Routes, Route } from 'react-router-dom';
import {
  useLocale,
  useQuerySync,
  QuerySyncConfig,
  getBase64JsonTransformer,
} from 'lib';
import { ErrorBoundary } from 'components/molecules';
import { useHeaderLocation } from 'components/organisms';
import { OverviewFilters } from 'types';
import {
  currentFiltersSelector,
  defaultFilters,
} from 'features/overview/alarmFilters';
import { setFilter } from 'features/overview';
import { ProvideCustomerContext } from './provideCustomerContext';
import { ProvideWebSocket } from './provideWebSocket';
import { AlarmOverview } from './overview';
import { AlarmDetailsGuard } from './details';
import { useAutoRefreshStoredTokens } from './useAutoRefreshStoredTokens';
import { useAlarmNotifications } from './useAlarmNotifications';
import { useAlarmSoundEffect } from './useAlarmSoundEffect';
import { useAuthenticationRedirect } from './useAuthenticationRedirect';
import { useCustomerId } from '../../../lib/hooks/useCustomerId';

const SoundEffect = memo(() => {

  useAlarmSoundEffect();
  return null;

});

const AlarmNotifications = memo(() => {

  useAlarmNotifications();
  return null;

});

const querySyncConfig: QuerySyncConfig<OverviewFilters> = {
  key: 'filters',
  selector: currentFiltersSelector,
  actionCreator: (filter) => setFilter({
    source: 'external',
    filter,
  }),
  defaultValue: defaultFilters,
  transformer: getBase64JsonTransformer(defaultFilters),
};

// Provides the customer context and /alarms routing
// Required authentication
const AlarmSwitch: FC = memo(() => {

  const { t } = useLocale();

  useQuerySync(querySyncConfig);
  useCustomerId();

  useHeaderLocation(useMemo(() => ({
    label: t('TopBar_Alarms'),
    location: '/alarms',
    order: 0,
  }), [t]));

  useAutoRefreshStoredTokens();

  return (
    <ErrorBoundary>
      <SoundEffect/>
      {'Notification' in window && <AlarmNotifications/>}
      <ProvideWebSocket>
        <ProvideCustomerContext>
          <Routes>
            <Route path={'/:alarmId'} element={<AlarmDetailsGuard/>}/>
            <Route path={'/'} element={<AlarmOverview/>}/>
          </Routes>
        </ProvideCustomerContext>
      </ProvideWebSocket>
    </ErrorBoundary>
  );

});

// Guard the 'AlarmSwitch' component against unauthenticated users, as the component makes requests that
// require authentication
export const AlarmSwitchGuard = memo(() => {

  const redirect = useAuthenticationRedirect();

  if (redirect) return <>{redirect}</>;

  return (
    <ErrorBoundary>
      <AlarmSwitch/>
    </ErrorBoundary>
  );

});
