import {
  RS,
  StaticResponseState, AbortableThunkArg,
} from 'types';
import {
  createSlice,
  asyncPollableDefaults,
  withPollableFulfilled,
  withPollablePending,
  withPollableRejected,
  createThunk, defaultRetryConfig, withPollableRetrying, api,
} from 'lib';
import { ResponseGroup } from '@x-guard/xgac-types/xgac';

const name = 'details/response/static';

export type FetchStaticResponseArgs = AbortableThunkArg & {
  alarmId: string;
};

export const fetchStaticResponse = createThunk<ResponseGroup[], FetchStaticResponseArgs>({
  name,
  handler: async ({ alarmId, controller: { signal } }) => {

    const { data } = await api.get<{ result: ResponseGroup[] }>(`/alarms/${alarmId}/response/static`, {
      signal,
    });

    return data.result;

  },
  retryConfig: defaultRetryConfig,
});

export const staticResponse = createSlice<StaticResponseState, typeof name>({
  name,
  initialState: {
    ...asyncPollableDefaults,
  },
  extraReducers: (builder) => builder
    .addCase(fetchStaticResponse.pending, withPollablePending)
    .addCase(fetchStaticResponse.fulfilled, withPollableFulfilled)
    .addCase(fetchStaticResponse.rejected, withPollableRejected)
    .addCase(fetchStaticResponse.retrying, withPollableRetrying),
});

export const staticResponseStatusSelector = (state: RS) => state.details.response.static.status;
export const staticResponseValueSelector = (state: RS) => state.details.response.static.value;
export const staticResponseSelector = (state: RS) => state.details.response.static;
