import { createSelector } from '@reduxjs/toolkit';
import { createAction } from '@reduxjs/toolkit/src';
import { PopulatedAlarmHelper, RS } from 'types';

export const addHelper = createAction<PopulatedAlarmHelper>('details/helpers/addHelper');
export const updateHelper = createAction<PopulatedAlarmHelper>('details/helpers/updateHelper');

export const resultSelector = (state: RS) => state.details.helpers.value;
export const confirmedHelpingSelector = createSelector(
  resultSelector,
  (result) => result.filter((helper) => helper.confirmedHelping),
);
export const statusSelector = (state: RS) => state.details.helpers.status;
export const errorSelector = (state: RS) => state.details.helpers.error;
export const isRetryingSelector = (state: RS) => state.details.helpers.isRetrying;
