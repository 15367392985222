import { SurveyState } from 'types';
import { TranslationFunc, TranslationKey } from 'config/i18n';

export const getSurveyMessageBody = (values: SurveyState, t: TranslationFunc): string => {

  const sections = [];

  // Appends a message section
  // Takes an optional message, which defaults to the translation associated with the value of the key
  const section = (key: keyof SurveyState, message?: string): void => {

    // uses-translation-asset: "Alarm_Detail_Acknowledge.Comment.*"
    sections.push(t(`Alarm_Detail_Acknowledge.Comment.${key}` as TranslationKey, {
      message: message ?? t(`Alarm_Detail_Acknowledge.Survey.Item.${values[key]}` as TranslationKey),
    }));

  };

  section('customerHappy');

  if (values.customerHappy === 'no' || values.customerHappy === 'unknown') {

    section('audioQualityOk');

    if (values.audioQualityOk === 'no') {

      section('audioQualityFeedback');

    }

    section('responseOk');

    if (values.responseOk === 'no') {

      section('responseFeedback', values.responseFeedback);

    }

    section('positionOk');

    if (values.positionOk === 'no') {

      section('positionFeedback');

    }

  }

  section('comment', values.comment);

  return sections.join('\n');

};
