import {
  AsyncStatus,
  PaginatedAsyncState,
  AsyncPollableState,
  AsyncState, AbortableThunkArg,
} from 'types';
import { RetryConfig } from 'lib/helpers/redux/createThunk';
import { getExponentialBackoff } from 'lib/helpers/redux/exponentialBackoff';
import { SerializedError } from '@reduxjs/toolkit';

export * from './createSlice';
export * from './createThunk';
export * from './exponentialBackoff';
export * from './watch';
export * from './helpers';

export const asyncDefaults = {
  status: AsyncStatus.Idle,
  value: null,
  error: null,
// Using any here is fine, as we aren't making any assertions on the value type
// eslint-disable-next-line
} as AsyncState<any>

export const asyncPollableDefaults = {
  ...asyncDefaults,
  isRetrying: false,
  realStatus: AsyncStatus.Idle,
  controller: null,
// Using any here is fine, as we aren't making any assertions on the value type
// eslint-disable-next-line
} as AsyncPollableState<any>

// Using any here is fine, as we aren't making any assertions on the value type
// eslint-disable-next-line
export const paginationDefaults: PaginatedAsyncState<any> = {
  ...asyncPollableDefaults,
  total: 0,
  value: [],
};

export const isAbortError = (error: Error | SerializedError): boolean => {

  return ['AbortError', 'CanceledError'].includes(error.name);

};

export const defaultRetryConfig: RetryConfig<AbortableThunkArg> = {
  // Abort errors should stop the retry loop
  shouldRetry: ({ error, arg }) => !isAbortError(error) && !arg.controller.signal.aborted,
  // Only dispatch on the very first retry,
  dispatchOnRetry: ({ retryIndex }) => retryIndex === 0,
  // Wait at least 0.5s, and 10s at most between attempts
  waitProvider: () => getExponentialBackoff({
    minWait: 500,
    maxWait: 10000,
    factor: 1.2,
  }),
};
