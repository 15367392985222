import React, {
  FC, MutableRefObject, useCallback, useContext, useEffect, useState,
} from 'react';

import { PopulatedAlarm } from 'types';
import { useAnimatedOverlay } from 'components';
import { useSelector } from 'react-redux';
import { alarmDetailsSelector } from 'features/details';
import { Responder } from '@x-guard/xgac-types/xgac';
import { alarmContext } from '../alarmContext';
import { ContactOptions } from './contactOptions';

export type ContactOptionModalItemProps = {
  responder: Responder;
  onClick: () => void;
};

export type ContactOptionImplProps = {
  isAlarmCreator: boolean;
  responder: Responder;
  alarm: PopulatedAlarm;
  onDismiss: () => void;
  setOnBack: (handler: () => void) => void;
  onContactConfirmation: () => void;
};

export type ContactOptionCondition = boolean | ((responder: Responder) => boolean);

export type ContactOptionConfig = {
  modalItem: FC<ContactOptionModalItemProps>;
  enabled: ContactOptionCondition;
  navigateTo: FC<ContactOptionImplProps>;
  disallowDismissal?: boolean;
};

export type ContactType =
  | 'pushMessage'
  | 'automaticPhoneMessage'
  | 'phoneCall'
  | 'smsMessage'
  | 'email';

export type ContactOptionsMap = Record<ContactType, ContactOptionConfig>;

export const Contact: FC<{ containerRef: MutableRefObject<HTMLDivElement> }> = ({ containerRef }) => {

  const {
    onDismissContact: onDismiss,
    showContact: enabled,
    contact,
  } = useContext(alarmContext);
  const details = useSelector(alarmDetailsSelector);

  const [displayOptions, setDisplayOptions] = useState(false);

  useEffect(() => {

    setDisplayOptions(enabled);

  }, [enabled]);

  const dismissOptions = useCallback(() => {

    setDisplayOptions(false);
    onDismiss();

  }, [onDismiss]);

  const {
    shouldRender,
    overlayProps,
  } = useAnimatedOverlay(displayOptions);

  return (
    <>
      {shouldRender && (
        <ContactOptions
          containerRef={containerRef}
          overlayProps={overlayProps}
          alarm={details}
          config={contact}
          onDismiss={dismissOptions}
        />
      )}
    </>
  );

};
