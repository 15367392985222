import {
  api, childController, CustomerContext, useWebSocketContext,
} from 'lib';
import { useDispatch } from 'react-redux';
import { IncomingEntityMessage } from 'classes/xgacWebSocket';
import { PopulatedAlarm } from 'types';
import { addOpenAlarm, updateAlarm } from 'features/overview';
import { useEffect } from 'react';
import { DbOperations } from '@x-guard/xgac-types/xgac';

export const useAlarmOverviewSubscription = ({ signal, customer }: CustomerContext) => {

  const ws = useWebSocketContext();
  const dispatch = useDispatch();

  useEffect(() => {

    if (!ws) return undefined;

    // Subscription is dependent on the customer
    const controller = childController(signal);

    // Update alarms in state
    const onUpdate = async (message: IncomingEntityMessage) => {

      dispatch(updateAlarm(message.document));

    };

    // Handle new alarms by appending them to the state, and subscribing to updates
    const onCreate = async (message: IncomingEntityMessage) => {

      const { data } = await api.get<PopulatedAlarm>(`/alarms/${message.document._id}`);

      if (controller.signal.aborted) return;

      dispatch(addOpenAlarm(data));

      await ws.subscribe<IncomingEntityMessage>({
        signal: controller.signal,
        data: {
          entity: 'Alarm',
          events: [DbOperations.Update],
          filter: {
            _id: message.document._id,
          },
        },
        handler: onUpdate,
      });

    };

    ws.subscribe<IncomingEntityMessage>({
      signal: controller.signal,
      data: {
        entity: 'Alarm',
        ...(customer.isAlarmCenter ? {
          alarmCenter: customer._id,
        } : {
          customers: [customer._id],
        }),
        events: [
          DbOperations.Create,
          DbOperations.Update,
        ],
        filter: {
          'ack.value': false,
        },
      },
      handler: {
        Alarm: {
          [DbOperations.Create]: onCreate,
          [DbOperations.Update]: onUpdate,
        },
      },
    });

    return () => controller.abort();

  }, [customer, signal, ws, dispatch]);

};
