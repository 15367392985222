import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { refreshTokenSelector } from '../../features/identity/auth/exports';
import { refresh } from '../../features/identity/auth/refresh';

export const useAuthRefresh = () => {

  const refreshToken = useSelector(refreshTokenSelector);
  const dispatch = useDispatch();

  useEffect(() => {

    if (!refreshToken) return undefined;

    const timeoutId = setTimeout(() => {

      dispatch(refresh({
        refreshToken,
      }));

    }, 1000 * 60 * 30);

    return () => {

      clearTimeout(timeoutId);

    };

  }, [refreshToken]); // eslint-disable-line react-hooks/exhaustive-deps

};
