import { AxiosInstance } from 'axios';
import { accessTokenSelector } from 'features/identity/auth/exports';

export const attachAuthorizationInterceptor = (api: AxiosInstance): void => {

  api.interceptors.request.use(async (config) => {

    const { store } = await import('../../features');

    const accessToken = accessTokenSelector(store.getState());

    if (accessToken) {

      config.headers.Authorization = `Bearer ${accessToken}`;

    }

    return config;

  });

};
