import { XGACWebSocket } from 'classes/xgacWebSocket';
import { createContext, useContext } from 'react';

export type WebSocketContext = XGACWebSocket | null;

export const webSocketContext = createContext<WebSocketContext>(null);

export const useWebSocketContext = (): WebSocketContext => {

  return useContext(webSocketContext);

};
