import EventEmitter from 'events';
import type TypedEmitter from 'typed-emitter';
import { logger } from 'classes/logger';
import { Connection } from 'types';

type Events = {
  open: (event: Event) => void;
  close: (event: CloseEvent) => void;
  message: (data: object) => void;
  error: (event: ErrorEvent) => void;
};

export class SafeWebSocket extends (EventEmitter as { new (): TypedEmitter<Events> }) {

  protected state: Connection = Connection.Disconnected;

  protected disposed = false;

  protected ws: WebSocket = null;

  public connect(url: string): Promise<void> {

    // Don't allow using the websocket after it has been disposed
    if (this.disposed) {

      logger.error('Cannot connect to a disposed websocket, please instantiate a new websocket instead');
      return undefined;

    }

    if (this.state !== Connection.Disconnected) {

      return undefined;

    }

    this.state = Connection.Connecting;

    this.ws = new WebSocket(url);

    this.ws.addEventListener('open', this.onOpen.bind(this));
    this.ws.addEventListener('close', this.onClose.bind(this));
    this.ws.addEventListener('message', this.onMessage.bind(this));
    this.ws.addEventListener('error', this.onError.bind(this));

    return new Promise<void>((resolve, reject) => {

      let cleanUp: () => void = null;

      const connectedHandler = () => {

        cleanUp();
        resolve();

      };

      const errorHandler = (event: ErrorEvent) => {

        cleanUp();
        reject(new Error(event.message));

      };

      cleanUp = () => {

        this.off('open', connectedHandler);
        this.off('error', errorHandler);

      };

      this.on('open', connectedHandler);
      this.on('error', errorHandler);

    });

  }

  protected send(object: object): void {

    this.ws.send(JSON.stringify(object));

  }

  public disconnect(): void {

    this.ws?.close();
    this.ws = null;

  }

  protected guardDisposed(): boolean {

    if (this.disposed) {

      logger.error(new Error('WS has been disposed, but was used'));
      return true;

    }

    return false;

  }

  private onOpen(event: Event): void {

    if (this.guardDisposed()) return;

    this.emit('open', event);

  }

  private onClose(event: CloseEvent): void {

    this.emit('close', event);

  }

  private onMessage(event: MessageEvent): void {

    if (this.guardDisposed()) return;

    const data = JSON.parse(event.data);

    this.emit('message', data);

  }

  private onError(event: ErrorEvent): void {

    if (this.guardDisposed()) return;

    this.emit('error', event);

  }

}
