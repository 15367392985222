import React, {
  FC, useEffect, useMemo, useState,
} from 'react';
import { WebSocketContext, webSocketContext } from 'lib';
import { XGACWebSocket } from 'classes/xgacWebSocket';
import { accessTokenSelector } from 'features/identity/auth';
import { XGAC_WS_API_URL } from 'config';
import { logger } from 'classes/logger';
import { useSelector } from 'react-redux';

export const ProvideWebSocket: FC = ({ children }) => {

  const controller = useMemo(() => new AbortController(), []);
  const [webSocketContextValue, setWebSocketContextValue] = useState<WebSocketContext>(null);
  const accessToken = useSelector(accessTokenSelector);

  useEffect(() => {

    return () => controller.abort();

  }, [controller]);

  const ws = useMemo(() => {

    return new XGACWebSocket(controller.signal);

  }, [controller]);

  useEffect(() => {

    ws.setAccessToken(accessToken);

  }, [accessToken, ws]);

  useEffect(() => {

    const connect = async () => {

      try {

        await ws.connect(XGAC_WS_API_URL);

        if (!controller.signal.aborted) {

          setWebSocketContextValue(ws);

        }

      } catch (e) {

        logger.error(e);

      }

    };

    connect();

  }, [ws]); // eslint-disable-line

  return (
    <webSocketContext.Provider value={webSocketContextValue}>
      {children}
    </webSocketContext.Provider>
  );

};
