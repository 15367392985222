import {
  asyncPollableDefaults,
  createSlice, getLocal, setLocal, setSession,
  withPollableFulfilled,
  withPollablePending,
  withPollableRejected,
} from 'lib';
import {
  AsyncStatus, AuthState, AnyStoredToken, StoredToken, RS,
} from 'types';
import { original } from '@reduxjs/toolkit';
import { User } from '@x-guard/xgac-types/xgac';
import { cloneDeep, remove } from 'lodash';
import { login } from './login';
import { logout, removeStoredToken } from './exports';
import { refresh } from './refresh';
import { isStoredToken } from '../../../lib/helpers/storedTokens';

export * from './exports';

const name = 'identity/auth';

const createOrUpdateToken = (
  input: AnyStoredToken[],
  user: User,
  refreshToken: string,
): AnyStoredToken[] => {

  const storedTokens = cloneDeep(input);
  const existingToken = storedTokens
    .filter(isStoredToken)
    .find((token) => token.user._id === user._id);

  const loggedId = new Date().toISOString();

  if (existingToken) {

    existingToken.loggedIn = loggedId;
    existingToken.refreshToken = refreshToken;

  } else {

    storedTokens.push({
      loggedIn: loggedId,
      user: {
        _ref: 'User',
        _id: user._id,
        name: user.name,
      },
      refreshToken,
    });

  }

  setLocal('tokens', storedTokens);

  return storedTokens;

};

const removeToken = (input: AnyStoredToken[], token: StoredToken): AnyStoredToken[] => {

  const storedTokens = cloneDeep(input);

  remove(storedTokens, (t) => isStoredToken(t) && t.user._id === token.user._id);

  setLocal('tokens', storedTokens);

  return storedTokens;

};

export const getInitialState = () => ({
  ...asyncPollableDefaults,
  token: null,
  type: null,
  storedTokens: getLocal('tokens', []),
});

export const auth = createSlice<AuthState, typeof name>({
  name,
  initialState: getInitialState(),
  extraReducers: (builder) => builder
    .addCase(logout, () => {

      setSession('auth', null);

      return getInitialState();

    })
    .addCase(removeStoredToken, (state, action) => {

      state.storedTokens = removeToken(original(state.storedTokens), action.payload);

    })
    .addCase(login.pending, (state, action) => {

      state.type = action.meta.arg.type;
      state.token = action.meta.arg.type === 'storedToken' ? action.meta.arg.token : null;

      withPollablePending(state, action);

    })
    .addCase(login.fulfilled, (state, action) => {

      withPollableFulfilled(state, action);
      setSession('auth', action.payload);

      state.storedTokens = createOrUpdateToken(
        original(state.storedTokens),
        action.payload.context.user,
        action.payload.login.refreshToken,
      );

    })
    .addCase(login.rejected, withPollableRejected)
    .addCase(refresh.fulfilled, (state, action) => {

      state.value.login = action.payload;

      state.storedTokens = createOrUpdateToken(
        original(state.storedTokens),
        state.value.context.user,
        action.payload.refreshToken,
      );

    })
    .addCase(refresh.rejected, (state, action) => {

      state.status = AsyncStatus.Rejected;
      state.realStatus = AsyncStatus.Rejected;
      state.error = action.payload;
      state.value = null;

    }),
});

export const authStateSelector = (state: RS) => state.identity.auth;
