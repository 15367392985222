import { XGAC_FUSION_AUTH_CLIENT_ID } from 'config';
import { JWTAuthPair } from 'types';
import { authApi, createThunk } from 'lib';

type RefreshAuthArgs = {
  refreshToken: string;
};

type RefreshResponse = JWTAuthPair & { userId: string };

export const refresh = createThunk<JWTAuthPair, RefreshAuthArgs>({
  name: 'identity/auth/refresh',
  handler: async ({ refreshToken }) => {

    const { data } = await authApi.post<RefreshResponse>('/auth/refresh', {
      refreshToken,
      clientId: XGAC_FUSION_AUTH_CLIENT_ID,
    });

    return {
      refreshToken: data.refreshToken,
      accessToken: data.accessToken,
    };

  },
});
